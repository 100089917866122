<template>
  <div class="wrapper">
    <layout-SideMenu />
    <div class="content-box" :class="{'content-collapse':collapse}">
      <layout-header />
      <div class="content">
        <router-view v-if="isRouterAlive"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home", // 入口配置组件
  provide() {
    return {
      reload: this.reload
    };
  },
  data() {
    return {
      isRouterAlive: true,
      collapse: false
    };
  },
  components: {
    LayoutSideMenu: () => import("@/components/LayoutSideMenu.vue"),
    LayoutHeader: () => import("@/components/LayoutHeader.vue")
  },
  watch: {
    $route(to, from) {
      console.log(to, from);
    }
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    }
  },
  created() {
    this.$bus.$on("collapse", msg => {
      this.collapse = msg;
    });
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  .floatBtn {
    position: absolute;
    right: 10px;
    bottom: 60px;
    z-index: 9999;
    div {
      margin-bottom: 10px;
    }
  }
}
</style>
